defineDs('DanskeSpil/Domain/Tips/Scripts/SystemGuide', [
  'Common/Framework/EventHandling/Scripts/Event',
], function (Event) {
  Event.subscribe('overlay-opened', function (params) {

    /**
     * Tips systems tab navigation
     */
    const $container = params?.$container;
    const $tabs = $container?.querySelectorAll('.js-tips-tap .tab'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
    const $tabPages = $container?.querySelectorAll('.js-tips-tap .tab-page'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element

    $tabs.forEach(($element) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
      $element.addEventListener('click', (event) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element

        $tabs.forEach(($element) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
          $element.classList.remove('active');
        });

        const $element = event.target;
        const index = $element.getAttribute('data-tabindex'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
        $element.classList.add('active');

        $tabPages.forEach(($element) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
          $element.classList.add('hidden');
        });

        $tabPages[index]?.classList.remove('hidden');
      });
    });

    /**
     * Tips systems toggle type buttuns between r- and u-system select
     */
    const typeButtonClicked = (event) => {
      const $element = event.target;

      if (!$element.classList.contains('selected')) {
        $container.querySelector('.js-type-button.selected').classList.remove('selected'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
        $element.classList.add('selected');

        $container.querySelector('#r-select')?.classList.toggle('hidden', $element.id !== 'r-button'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
        $container.querySelector('#u-select')?.classList.toggle('hidden', $element.id !== 'u-button'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
      }
    };

    $container.querySelector('#r-button')?.addEventListener('click', typeButtonClicked); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
    $container.querySelector('#u-button')?.addEventListener('click', typeButtonClicked); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element

    /**
     * Select on change link to chosen system
     */
    const selectSystemChanged = (event) => {
      const $element = event.target;
      const systemName = $element.value;
      if (systemName) {
        const $selected = $element.querySelector('[value=\'' + systemName + '\']'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
        const link = $selected.getAttribute('data-url'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
        if (link) {
          document.location.href = link;
        }
      }
    };

    $container.querySelector('#r-select')?.addEventListener('change', selectSystemChanged); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
    $container.querySelector('#u-select')?.addEventListener('change', selectSystemChanged); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element

  });
});
